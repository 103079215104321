function heightElement(){

  let Elements = document.querySelectorAll('.formatCustom');


  if (Elements.length > 0){
    for (let i = 0; i < Elements.length; i++ ){
      let Element = Elements[i]; 
      let Width = Element.clientWidth;
      let Height = (Width/16)*9;
      Element.style.height = Height + "px";
      window.onresize = heightElement;
    }
  }
} heightElement();