var swiper = new Swiper(".single_container_galery", {
    slidesPerView: 3.4,
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1.8,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2.3,
          spaceBetween: 30,
        },
        980: {
            slidesPerView: 2.9,
            spaceBetween: 30,
          },
        1024: {
          slidesPerView: 3.4,
          spaceBetween: 30,
        },
        1440: {
          slidesPerView: 3.4,
          spaceBetween: 10,
        }
      }
  });
