$(document).ready(function () {
	
	// $('header nav').meanmenu({
	// 	meanScreenWidth: "768"
	// });
	
	$('.singlearticle.article__body > p:nth-child(2)').append( '<div class="newsletter-bloc"><div class="newsletter-bloc__content"><h3>Newsletter</h3><p>Inscrivez-vous à notre Newsletter et ne ratez plus aucun article.</p></div><a href="https://daardaar.us10.list-manage.com/subscribe?u=dda6a856da5e8cc12ccc85681&id=d92bf2de83" target="blank" class="btn">Inscription</a></div>' );
	
	
let slides = document.querySelectorAll('#slides .slide');
let currentSlide = 0;
let slideInterval = setInterval(nextSlide,3500);

function nextSlide(){
	
	slides[currentSlide].className = 'slide';
	currentSlide = (currentSlide+1)%slides.length;
	slides[currentSlide].className = 'slide showing';
}

let playing = true;
let pauseButton = document.getElementById('pause');

function pauseSlideshow(){
	pauseButton.innerHTML = 'Play';
	playing = false;
	clearInterval(slideInterval);
}

function playSlideshow(){
	pauseButton.innerHTML = 'Pause';
	playing = true;
	slideInterval = setInterval(nextSlide,2000);
}

// pauseButton.onclick = function(){
// 	if(playing){ pauseSlideshow(); }
// 	else{ playSlideshow(); }
// };
});