import '../sass/app.scss'

import './main'
// import './main-min'
// import './meanmenu'

import './plugins'
import './burger'
import './swiper'
import './glightbox'
import './height'
