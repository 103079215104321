let parent = document.querySelectorAll('.single_container_galery')

function lightbox() {
  if (!parent) return
  
  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true
  });

  lightbox.on('open', (target) => {
    console.log('lightbox opened');
  });

} lightbox()