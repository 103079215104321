function burger_action(){
  console.log('burger')
  const header = document.querySelector('.topbar');
  const burger = document.querySelector('.burger');
  if(!burger || !header) return;
  console.log('burger ok')
  burger.addEventListener('click', () => {
    
    header.classList.toggle('isActive')
    document.body.classList.toggle('overflow')

    if (header.classList.contains("isActive")) {
      burger.setAttribute('aria-expanded', true)
    } else {
      burger.setAttribute('aria-expanded', false)
    }
  })

} burger_action()